<template>
  <b-card>
    <b-input-group>
      <b-form-checkbox
          v-model="form.isEnableDepositAffiliate"
          name="recommendFromDeposit"
          switch
      >
        แนะนำเพื่อนจากยอดฝาก
      </b-form-checkbox>
    </b-input-group>
    <div v-if="form.isEnableDepositAffiliate">
      <h4 class="mb-4">
        แนะนำเพื่อนจากยอดฝาก <br>
        <small class="text-danger">***สามารถนำไปเล่นสล็อตได้เท่านั้น***</small>
      </h4>
      <template v-if="previewDepositPromotionImage">
        <b-img :src="previewDepositPromotionImage" class="w-50" />
        <div class="d-flex my-2">
          <b-form-text class="mr-2">{{ previewDepositPromotionImage }}</b-form-text>
          <a href="#" @click.prevent="form.depositAffiliateImage = null "> [ ลบ ] </a>
        </div>
      </template>
      <b-form-group
          label="รูปภาพ *"
          label-for="depositAffiliateImage"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="depositAffiliateImage"
            rules="required"
        >
          <b-form-file
              id="depositAffiliateImage"
              v-model="form.depositAffiliateImage"
              :state="errors[0] ? false : null"
          ></b-form-file>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
          :label="$t('cashback.turnover_point')"
      >
        <b-row class="mb-3">
          <b-col>
            <b-input-group prepend="ประเภทเทิร์น">
              <b-select
                  id="turnType"
                  disabled
                  value="0"
              >
                <b-select-option value=0 >เทิร์นเครดิต</b-select-option>
              </b-select>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <ValidationProvider
                name="ยอดเทิร์น"
                rules="required|min:1"
            >
              <b-input-group prepend="ยอดเทิร์น" append="เท่า">
                <b-input
                    v-model="form.depositAffiliateTurnRate"
                    type="number"
                ></b-input>
              </b-input-group>
            </ValidationProvider>
          </b-col>
          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox
                    v-model="form.haveMaxWithdrawDepositAffiliate"
                    name="haveMaxWithdrawDepositAffiliate"
                    switch
                >
                </b-form-checkbox>
              </b-input-group-prepend>

              <b-input-group-prepend is-text>
                อั้นถอน
              </b-input-group-prepend>

              <b-input
                  v-model="form.maxWithdrawDepositAffiliate"
                  type="number"
                  :disabled="!form.haveMaxWithdrawDepositAffiliate"
              ></b-input>

              <template #append>
                <b-form-select
                    v-model="form.maxWithdrawTypeDepositAffiliate"
                    style="width: 80px; min-width: 80px"
                    :disabled="!form.haveMaxWithdrawDepositAffiliate"
                >
                  <b-form-select-option :value="0">เครดิต</b-form-select-option>
                  <b-form-select-option :value="1">เท่า</b-form-select-option>
                </b-form-select>
              </template>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <hr />
    <b-input-group>
      <b-form-checkbox
          v-model="form.isEnableBetAffiliate"
          name="recommendFromBet"
          switch
      >
        แนะนำเพื่อนจากยอดแทง
      </b-form-checkbox>
    </b-input-group>
    <div v-if="form.isEnableBetAffiliate">
      <h4 class="mb-4">แนะนำเพื่อนจากยอดแทง</h4>
      <template v-if="previewAffiliateImage">
        <b-img :src="previewAffiliateImage" class="w-50" />
        <div class="d-flex my-2">
          <b-form-text class="mr-2">{{ previewAffiliateImage }}</b-form-text>
          <a href="#" @click.prevent="form.betAffiliateImage = null "> [ ลบ ] </a>
        </div>
      </template>
      <b-form-group
          label="รูปภาพ *"
          label-for="betAffiliateImage"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="betAffiliateImage"
            rules="required"
        >
          <b-form-file
              id="betAffiliateImage"
              v-model="form.betAffiliateImage"
              :state="errors[0] ? false : null"
          ></b-form-file>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
          label="ยอดฝากเพื่อเปิดระบบแนะนำ (บาท) *"
          label-for="minDepositForEnableAffiliate"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="minDepositForEnableAffiliate"
            rules="required"
        >
          <b-input
              id="minDepositForEnableAffiliate"
              v-model="form.minDepositForEnableAffiliate"
              :state="errors[0] ? false : null"
          ></b-input>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
          label="ถอนขั้นต่ำ (บาท) *"
          label-for="minWithdrawAffiliate"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="minWithdrawAffiliate"
            rules="required"
        >
          <b-input
              id="minWithdrawAffiliate"
              v-model="form.minWithdrawAffiliate"
              :state="errors[0] ? false : null"
          ></b-input>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
          label="ถอนสูงสุดต่อวัน (จำนวนครั้ง) *"
          label-for="maxWithdrawAffiliateTimePerDay"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="maxWithdrawAffiliateTimePerDay"
            rules="required"
        >
          <b-input
              id="maxWithdrawAffiliateTimePerDay"
              v-model="form.maxWithdrawAffiliateTimePerDay"
              :state="errors[0] ? false : null"
          ></b-input>
        </ValidationProvider>
      </b-form-group>
      <h6>ค่าแนะนำจากยอดแทง</h6>
      <b-table-simple style="min-height: 0 !important">
        <b-thead>
          <b-tr>
            <b-th>ระดับเลเวล</b-th>
            <b-th>ค่าแนะนำสล็อต</b-th>
            <b-th>ค่าแนะนำยิงปลา</b-th>
            <b-th>ค่าแนะนำคาสิโน</b-th>
            <b-th>ค่าแนะนำกีฬา</b-th>
            <b-th />
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-if="form.affiliateLevelSettings.length === 0">
            <b-td
                colspan="3"
                class="text-muted"
            >ไม่มีข้อมูล</b-td>
          </b-tr>
          <b-tr
              v-for="(affiliateLevelSetting, level) in form.affiliateLevelSettings"
              :key="level"
          >
            <b-td>
              <b-form-group :label-for="`level-${level}`">
                <b-form-input
                    :id="`level-${level}`"
                    :value="level + 1"
                    readonly
                ></b-form-input>
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`commissionRate-${level}-slot`">
                <b-input-group append="%">
                  <b-form-input
                      :id="`commissionRate-${level}-slot`"
                      v-model="affiliateLevelSetting.commissionRate[gameType.SLOT]"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`commissionRate-${level}-fishing`">
                <b-input-group append="%">
                  <b-form-input
                      :id="`commissionRate-${level}-fishing`"
                      v-model="affiliateLevelSetting.commissionRate[gameType.FISHING]"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`commissionRate-${level}-casino`">
                <b-input-group append="%">
                  <b-form-input
                      :id="`commissionRate-${level}-casino`"
                      v-model="affiliateLevelSetting.commissionRate[gameType.CASINO]"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`commissionRate-${level}-sport`">
                <b-input-group append="%">
                  <b-form-input
                      :id="`commissionRate-${level}-sport`"
                      v-model="affiliateLevelSetting.commissionRate[gameType.SPORT]"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-td>
            <b-td>
              <b-button
                  variant="danger"
                  :disabled="level === 0"
                  @click="onRemove(level)"
              >
                <i class="uil uil-trash"></i>
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="3">
              <b-button
                  variant="success"
                  @click="onAdd"
              >
                <i class="uil uil-plus"></i>
                เพิ่มเลเวล
              </b-button>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>

export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | ระบบแนะนำเพื่อน',
  },
  props: {
    agentData: {
      type: Object,
      default: () => ({
        isEnableDepositAffiliate: false,
        depositAffiliateImage: null,
        depositAffiliateTurnRate: 0,
        haveMaxWithdrawDepositAffiliate: false,
        maxWithdrawDepositAffiliate: 0,
        maxWithdrawTypeDepositAffiliate: 0,

        isEnableBetAffiliate: false,
        betAffiliateImage: null,
        minDepositForEnableAffiliate: 0,
        minWithdrawAffiliate: 0,
        maxWithdrawAffiliateTimePerDay: 1,
        affiliateLevelSettings: []
      }),
    },
  },
  data() {
    return {
      form: {},
      gameType: {
        ALL: 0,
        SLOT: 1,
        FISHING: 2,
        CASINO: 3,
        SPORT: 4,
        GRAPH: 5,
      }
    }
  },
  computed: {
    previewAffiliateImage() {
      if(this.form.betAffiliateImage) {
        if (typeof this.form.betAffiliateImage === 'string') {
          return this.form.betAffiliateImage
        } else {
          return URL.createObjectURL(this.form.betAffiliateImage)
        }
      }
      return null
    },
    previewDepositPromotionImage() {
      if(this.form.depositAffiliateImage) {
        if (typeof this.form.depositAffiliateImage === 'string') {
          return this.form.depositAffiliateImage
        } else {
          return URL.createObjectURL(this.form.depositAffiliateImage)
        }
      }
      return null
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      isEnableDepositAffiliate: this.agentData.isEnableDepositAffiliate,
      depositAffiliateImage: this.agentData.depositAffiliateImage,
      depositAffiliateTurnRate: this.agentData.depositAffiliateTurnRate,
      haveMaxWithdrawDepositAffiliate: this.agentData.haveMaxWithdrawDepositAffiliate,
      maxWithdrawDepositAffiliate: this.agentData.maxWithdrawDepositAffiliate,
      maxWithdrawTypeDepositAffiliate: this.agentData.maxWithdrawTypeDepositAffiliate,

      isEnableBetAffiliate: this.agentData.isEnableBetAffiliate,
      betAffiliateImage: this.agentData.betAffiliateImage,

      minDepositForEnableAffiliate: this.agentData.minDepositForEnableAffiliate,
      minWithdrawAffiliate: this.agentData.minWithdrawAffiliate,
      maxWithdrawAffiliateTimePerDay: this.agentData.maxWithdrawAffiliateTimePerDay,
      affiliateLevelSettings: this.agentData.affiliateLevelSettings
    }
  },
  methods: {
    onRemove(index) {
      this.form.affiliateLevelSettings.splice(index, 1)
    },
    onAdd() {
      this.form.affiliateLevelSettings.push({
        commissionRate: {
          [this.gameType.SLOT]: 0,
          [this.gameType.FISHING]: 0,
          [this.gameType.CASINO]: 0,
          [this.gameType.SPORT]: 0,
        }
      })
    },
  },
}
</script>

<style></style>
